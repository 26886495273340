<template>
    <div class="plot-management">
        <van-search v-model="listQuery.massifName" @search="onSearch" placeholder="请输入地块名称" shape="round" input-align="center"  background="transparent"/>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text=""
                @load="onLoad"
                v-if="!isNoData"
            >
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="top">
                        <div class="top-left">
                            <img src="../../assets/images/plot/icon_list_plot.png"/>
                            <div class="content">
                                <p @click="seeDetails(item)">{{item.massifName}}</p>
                                <p>{{item.address}}</p>
                            </div>
                        </div>
                        <div class="top-right">
                            <p class="view-crop" @click="viewCrop(item.massifId)">查看作物</p>
                            <p class="view-crop" @click="viewAgriculturalRecords(item.massifId)">农事记录</p>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                            <div class="desc">
                                <!-- <span>联系人:</span>
                                <span>{{item.contacts}}</span>
                                <span class="tel">{{item.phone}}</span> -->
                                <span @click="lookPart(item)">前往管理围栏->></span>
                            </div>
                        </div>
                        <div class="bottom-right">
                            <van-field name="switch" label="" >
                                <template #input>
                                    <van-switch v-model="item.checked" :disabled="isAdmin" size="20" @input="onInput(item)"/>
                                </template>
                            </van-field>
                        </div>
                    </div>
                </div>
            </van-list>
            <div class="no-data" v-else>
                <div>
                    <img src="../../assets/images/icon_nodata.png"/>
                </div>
                <div>
                    <span class="no-data-text">暂无数据</span>
                </div>
            </div>
        </van-pull-refresh>
        <router-link :to="{path: '/addPlot'}" v-if="!isAdmin">
            <div class="btn-add">+</div>
        </router-link>
    </div>
</template>

<script>
import { listPageMassif, addMassif, modifyMassif, listPageMassifWork, addMassifWork, modifyMassifWork, getMassifWork} from '@/api/plotManagement';
import {
    getUserType, setMassifId
} from '@/utils/cookie'
export default {
    data() {
        return {
            isNoData: false,//是否无数据
            loading: false,
            finished: false,
            refreshing: false,
            list: [],
            isAdmin: true,//是否是管理员
            listQuery: {
                massifName: '', 
                parkId:'',
                createTime:'',
                endTime:'',
                startTime:'',
                /**分页 */
                total: 0, //分页总数
                pageIndex : 1, //当前默认页码
                pageSize : 500, //每页数据量
                orderBy: '', //排序
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        this.listPageMassif();
    },
    methods: {
        onLoad() {
            this.loading = false;
            this.refreshing = false;
        },
        onRefresh() {
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        /** 获取地块列表 */
        listPageMassif() {
            listPageMassif(this.listQuery).then(res => {
                if (res.code == 200) {
                    this.list = res.data || [];
                    this.list.map(item => {
                        item.checked = item.updater == 1? true : false;
                        return item;
                    })
                    // this.list = this.list.concat(res.data);
                    this.isNoData = this.list.length > 0 ? false : true;
                    this.listQuery.total = res.count || 0;
                    this.refreshing = false
                }
            })
        },
        /** 查看详情 */
        seeDetails(obj) {
            this.$router.push({name: 'addPlot', params: obj})
        },
        /** 查看作物 */
        viewCrop(id) {
            this.$router.push({name: 'cropManagement', params: {massifId: id}})
        },
        /** 查看农事记录 */
        viewAgriculturalRecords(id) {
            setMassifId(id);
            this.$router.push({name: 'agriculturalRecords', params: {massifId: id}})
        },
        /** 查看地块 */
        lookPart(val) {
            this.$router.push({name: 'lookPart', params: val});
        },
        /** 搜索 */
        onSearch() {
            this.listPageMassif();
        },
        /** 关闭启用 */
        onInput(val) {
            let text = val.updater == 1 ? '禁用' : '启用';
            this.$dialog.confirm({
                title: '提示',
                message: '是否'+text+'该地块？'
            }).then(() => {
                // this.checked = checked;
                val.updater = val.updater == 1 ? 0 : 1;
                modifyMassif(val).then(res => {
                    if (res.code == 200) {
                        this.listPageMassif();
                        this.$notify({type: 'success', message: ''+ text +'成功'});
                    }
                });
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .plot-management {
        width: 347px;
        margin: 0 auto;
        padding: 14px;
        max-height: 600px;
        overflow: auto;
        .item {
            position: relative;
            margin-bottom: 10px;
            padding: 12px;
            border-radius: 8px;
            background-color: #fff;
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-left {
                    display: flex;
                    align-items: center;
                    img {
                        width: 38px;
                        height: 38px;
                        margin-right: 12px;
                    }
                    .content {
                        p {
                            max-width: 180px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        p:nth-of-type(1) {
                            font-size: 14px;
                            font-weight: 500;
                            color: #4F4F4F;
                        }
                        p:nth-of-type(2) {
                            margin-top: 8px;
                            font-size: 12px;
                            color: #5EC182;
                        }
                    }
                }
                .top-right {
                    .view-crop {
                        line-height: 17px;
                        font-size: 12px;
                        color: #5EC182;
                    }
                    .view-crop:nth-of-type(2),
                    .view-crop:nth-of-type(3) {
                        margin-top: 8px;
                    }
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                .bottom-left {
                    .desc {
                        span {
                            font-size: 12px;
                            color: #8C99A9;
                        }
                    }
                }
            }
        }
        .btn-add {
            position: fixed;
            bottom: 70px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 26px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background-color: #5EC182;
        }
    }
</style>